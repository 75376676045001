###*
# @ngdoc object
# @name lpaMapUtils.factory:ProposalStyleFactory
#
# @description
#
###

class ProposalStyleFactory
  ### @ngInject ###
  constructor: (
    $log
    DispatchUnitStatus
    RestUtils
    DisplayLabel
  ) ->

    ProposalStyleBase = {
      clusterStyleCache: {}
      dispatchUnitStatuses: {}
    }

    return ProposalStyleBase

angular
  .module 'lpaMapUtils'
  .factory 'ProposalStyle', ProposalStyleFactory
